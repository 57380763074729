import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useRequest } from "../../hooks/useRequest";
import { useLocation, useNavigate } from "react-router-dom";
import GroupBuyGrid from "../../components/common/GroupBuyGrid";
import FilterableProducts from "../../components/search/FilterableProducts";
import { useAppContext } from "../../context/useAppContext";
import CategorySlider from "../../components/category/CategorySlider";
import TrendingProductsGrid from "../../components/common/TrendingProductsGrid";
import SlidingBanner from "../../components/common/SlidingBanner";
import { Skeleton } from "@mui/material";

const CategoryStyle = styled.div`
  .height_container {
    height: 50px;
  }
  .categories {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    padding: 0 0;
  }
  .products__related {
    padding: 50px 0;
  }
  @media (max-width: 768px) {
    .categories {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      padding: 40px 0;
    }
    .products__related {
      padding: 30px 0 50px;
    }
  }
`;

const Search = ({ showCategory = true, showProducts = true, showRelated = true }) => {
  const { categoriesData: contextCategories } = useAppContext();
  const [brandsData, setBrandsData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [fetchCategories, { isLoading: isFetchingCategories }] = useRequest();
  const [fetchBrands] = useRequest();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [categoriesIdList, setCategoriesIdList] = useState();
  const searchParams = new URLSearchParams(search);

  const categoryId = searchParams.get("categories[]");

  useEffect(() => {
    (async function () {
      const shouldSendCategoryId = categoryId && categoryId !== "interior" && categoryId !== "exterior";

      const categoryData = await fetchCategories({
        path: `/category/categories/json-tree?${shouldSendCategoryId ? `categoryId=${categoryId}` : ""}`,
      });
      const brandData = await fetchBrands({
        path: `/brand?limit=100&page=1`,
      });

      setCategoriesData(categoryData?.data);
      setBrandsData(brandData?.data?.docs);
    })();
  }, [categoryId]);

  const handleCategoryClick = (item) => {
    if (!categoriesIdList?.includes(item?._id)) {
      searchParams.delete("previousCategoryId");
      if (categoryId) {
        searchParams.append("previousCategoryId", categoryId);
      } else {
        searchParams.append("previousCategoryId", item?._id);
      }
      searchParams.delete("categories[]");
      searchParams.append("categories[]", item?._id);
      const newUrl = `/search?${searchParams.toString()}`;
      navigate(newUrl);
    }
  };

  const [fetchSlidingBanner, { isLoading: isFetchingSlidingBanner, state: slidingBanners }] = useRequest(
    `/instabuildslider?limit=10&page=1`
  );

  useEffect(() => {
    fetchSlidingBanner();
  }, []);

  return (
    <CategoryStyle className="container">
      {search.includes("instabuild=true") && (
        <>
          <div className="height_container"></div>
          <SlidingBanner
            reqHeight={180}
            onlyBanner={true}
            bannerData={slidingBanners?.data?.docs}
            leftdistance={30}
            loading={isFetchingSlidingBanner || !slidingBanners?.data?.docs}
          />
        </>
      )}
      <CategorySlider handleCategoryClick={handleCategoryClick} />

      {showProducts && (
        <>
          {isFetchingCategories ? (
            <Skeleton variant="rectangular"  width={"100%"} height={400} />
          ) : (
            <FilterableProducts
              categoriesData={categoriesData}
              brandsData={brandsData}
              setCategoriesList={setCategoriesIdList}
            /> 
          )}
        </>
      )}

      {showRelated && !search.includes("instabuild=true") && (
        <div className="products__related">
          <GroupBuyGrid />
        </div>
      )}
      <div className="container products__related">
        <TrendingProductsGrid
          heading={`Top Selling ${search.includes("instabuild=true") ? "Instabuild" : ""} Products`}
          api={"/product/top-selling"}
        />
      </div>
      <div className="height_container"></div>
    </CategoryStyle>
  );
};

export default Search;
