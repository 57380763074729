import React from "react";
import styled from "styled-components";
import categoryDummy from "../../assets/category-dummy.jpg";
import { defaultImage } from "../Image/Image";

const ProductReplyCardStyle = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px 5px;
  .review__content {
    flex: 1;
    .customer__name__rating__wrapper {
      display: flex;
      gap: 24px;
    }
    .customer__name,
    .customer__review__date,
    .customer__rating {
      color: #898989;
      font-size: 16px;
      font-weight: 600;
      text-transform: capitalize;
    }
    .customer__name {
      color: #363636;
    }
    .customer__rating {
      display: flex;
      gap: 4px;
      align-items: center;
    }
    .customer__review__date {
      font-weight: 400;
      margin: 10px 0 18px;
    }
    .customer__review {
      color: #898989;
      font-size: 14px;
      font-weight: 400;
    }
  }
  @media (max-width: 768px) {
    padding: 15px 15px;
    .review__content {
      .customer__name__rating__wrapper {
        align-items: center;
      }
      .customer__rating {
        svg {
          width: 16px;
        }
      }
      .customer__name {
        font-size: 14px;
      }
      .customer__review__date {
        font-size: 14px;
        margin: 4px 0 12px;
      }
    }
  }
`;

const ProductReplyCard = ({ customerName, customerReview, reviewDate }) => {
  return (
    <ProductReplyCardStyle>
      <div className="review__content">
        <div className="customer__name__rating__wrapper">
          {customerName && <div className="customer__name">{customerName}</div>}
        </div>
        {reviewDate && <div className="customer__review__date">{reviewDate}</div>}
        <div className="customer__review">{customerReview}</div>
      </div>
    </ProductReplyCardStyle>
  );
};

export default ProductReplyCard;
