import { createContext, useContext, useEffect, useState } from "react";

const GeoLocationContext = createContext();

export function GeoLocationProvider({ children }) {
  const [userLocation, setUserLocation] = useState(null);

  const enableLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            alert(
              "Location access is denied. Please enable location access in your browser settings."
            );
          } else {
            alert("Error getting location:", error);
          }
        }
      );
    } else {
      alert("Geolocation is not supported in this browser.");
    }
  };

  const handleLoc = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setUserLocation({
            latitude,
            longitude,
          });
        },
        (error) => {}
      );
    }
  };

  useEffect(() => {
    handleLoc();
  }, []);

  return (
    <GeoLocationContext.Provider value={{ userLocation, enableLocation }}>
      {children}
    </GeoLocationContext.Provider>
  );
}

export function useGeoLocation() {
  const context = useContext(GeoLocationContext);
  if (!context) {
    throw new Error("useGeoLocation must be used within a GeoLocationProvider");
  }
  return context;
}
