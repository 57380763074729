export const fileChecker = (file,onlyImage) => {

    const fileSizeInMB = file.size / (1024 * 1024)
    const videoAudioTypes = ['video/mp4', 'video/webm', 'video/ogg', 'audio/mpeg', 'audio/wav', 'audio/ogg']

    const onlyImgTypes = ['image/jpg', 'image/jpeg', 'image/png','image/avif']


    if (fileSizeInMB > 5) {
        return {
            value: false,
            message :'File must be less than 5Mb!'
        }
    }
    if (videoAudioTypes.includes(file.type)) {
        return {
            value: false,
            message :'Video & Audio Not Supported!'
        }
    }
    if (onlyImage && !onlyImgTypes.includes(file.type)) {
        return{
            value: false,
            message :'Only jpg, jpeg, png and avif is supported!'
        }
    }

    return {
        value:true
    }

}