import { staticUrl } from "./baseUrl";

export const getProductImages = (product) => {
  if (!(product && Object.keys(product).length)) return [];
  let itemImages = [];
  const baseUrl = staticUrl + "/";
  if (product?.image) {
    itemImages.push({
      original: baseUrl + product.image,
      thumbnail: baseUrl + product.image,
    });
  }
  if (
    product &&
    product?.additionalImages &&
    product?.additionalImages.length > 0
  ) {
    product?.additionalImages?.forEach((image) => {
      itemImages.push({
        original: baseUrl + image.name,
        thumbnail: baseUrl + image.name,
      });
    });
  }
  return itemImages;
};

export const getDate = (date) => {
  return new Date(date).toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
};

export const getParsedVariations = (variation) => {
  try {
    if (!variation) return { parsed: [], formatted: '' };

    if (Array.isArray(variation)) {
      const formatted = variation
        .map(item => `${item.name}: ${item.value}`)
        .join(', ');
      return { parsed: variation, formatted };
    }
  
    if (typeof variation === 'string') {
      const parsed = JSON.parse(variation);
      if (Array.isArray(parsed)) {
        const formatted = parsed
          .map(item => `${item.name}: ${item.value}`)
          .join(', ');
        return { parsed, formatted };
      }
    }
    return { parsed: [], formatted: '' };
  } catch (error) {
    console.warn('Variation parsing failed:', error);
    return { parsed: [], formatted: '' };
  }
};

export const getYouTubeId = (url) => {
  if(!url) return null;
  const match = url.match(/[?&]v=([^&]+)/);
  return match ? match[1] : null;
};

export const checkCODEnabled = (items) => {
  if(!items || items.length === 0) return false;
  
  if(items.every(item => item?.product?.cod === true)) return true;

  return false;
};