import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useRequest } from "../../../hooks/useRequest";
import { getDate, getParsedVariations } from "../../../utils/helper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useAppContext } from "../../../context/useAppContext";
import ReturnProductModal from "../../../components/modals/ReturnProductModal";
import { IoPrintSharp } from "react-icons/io5";
import TransactionDetailsPdf from "../../../components/pdf/TransactionDetailsPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { priceSymbol } from "../../../utils/symbol";
import parse from "html-react-parser";
import { staticUrl } from "../../../utils/baseUrl";
import moment from "moment/moment";

const TransactionDetailsStyle = styled.div`
  flex: 1;
  .bold__title {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: #333333;
    margin-bottom: 8px;
  }
  .checkout__now__button {
    color: #fff;
    text-align: center;
    font-size: 10px;
    font-weight: 700;
    line-height: 17px;
    border-radius: 5px;
    width: 100%;
    height: 30px;
    background: #ae0000;
    &:disabled {
      background: #a7a7a7;
    }
  }
  .transaction__header {
    border: 0.5px solid #cccccc;
    border-bottom: 0;
    padding: 12px 12px 0;
    .data {
      font-size: 12px;
      line-height: 20px;
      text-align: left;
      &.customer__name {
        text-transform: capitalize;
      }
    }
    .sales__info {
      font-size: 12px;
      line-height: 20px;
      color: #000000;
      padding: 12px 0;
      span {
        font-weight: 700;
      }
    }
  }
  .transaction__order__summary {
    border-left: 0.5px solid #cccccc;
    border-right: 0.5px solid #cccccc;
    border-bottom: 0.5px solid #cccccc;
    .transaction__order__summary__header {
      background: #f2f2f2;
      padding: 12px;
      display: flex;
      .transaction__order__th {
        font-size: 12px;
        font-weight: 700;
        line-height: 20px;
        text-align: center;
        flex: 1;
        &:first-child {
          text-align: left;
          flex: 0 0 500px;
        }
        &:last-child {
          text-align: right;
        }
      }
    }
    .transaction__order__summary__body {
      display: flex;
      padding: 12px;
      flex-direction: column;
    }
    .transaction__order__summary__row {
      display: flex;
      .transaction__order__td {
        flex: 1;
        &:last-child {
          text-align: right;
        }
      }
    }
    .transaction__order__td {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      &:first-child {
        flex: 0 0 500px;
      }
    }
    .transaction__item__details {
      display: flex;
      gap: 12px;
      .transaction__item__image {
        img {
          width: 108px;
        }
      }
      .transaction__item__info {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color: #333333;
        .product__name {
          font-weight: 700;
          color: #000;
          margin: 4px 0;
          text-transform: capitalize;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
        .product__description {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          span {
            font-weight: 700;
          }
          &.sku {
            margin: 10px 0;
          }
          &.attribute {
            margin-top: 5px;
            margin-bottom: 5px;
          }
        }
      }
    }
    .transaction__order__summary__footer {
      background: #f2f2f2;
      padding: 12px;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      display: flex;
      justify-content: flex-end;
      gap: 100px;
    }
    .transaction__payment__info {
      padding: 12px;
      display: flex;
      justify-content: space-between;
      .transaction__payment__method {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        text-transform: capitalize;
        span {
          font-weight: 700;
        }
      }
      .transaction__order__total {
        font-size: 12px;
        line-height: 12px;
        text-align: left;
        display: flex;
        gap: 100px;
        margin: 16px 0;
        &:first-child,
        &:last-child {
          margin: 0;
        }
        &.bold {
          font-weight: 700;
        }
        span {
          flex: 1;
        }
      }
    }
  }
  .transaction__logs {
    border: 0.75px solid #d9d9d9;
    margin-top: 10px;
    padding: 12px;
    .log__info {
      font-size: 12px;
      margin: 4px 0;
      line-height: 20px;
      span {
        font-weight: 600;
      }
    }
  }
  .transaction__order__status {
    border: 0.75px solid #d9d9d9;
    padding: 40px 0 32px;
    margin-top: 10px;
    .css-1vyamtt-MuiStepLabel-labelContainer {
      color: #898989;
    }
    .Mui-active,
    .Mui-completed {
      color: #3f8e00;
      .MuiStepConnector-line {
        border-color: #3f8e00;
      }
    }
    .cancelled,
    .return {
      .Mui-active,
      .Mui-completed {
        color: #ff0000;
      }
    }
    .MuiStepConnector-line {
      border-top-width: 2px;
    }
    .transaction__order__action {
      padding: 0 20px;
      margin-top: 32px;
      display: flex;
      gap: 20px;
      button {
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        height: 36px;
        padding: 0 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        color: #ffffff;
        &.dark {
          background: #000000;
        }
        &.accent {
          background: #ae0000;
        }
      }
    }
  }
`;

const PrintStyle = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  .print {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    color: #303030;
    cursor: pointer;
    &:hover {
      color: #ae0000;
      text-decoration: underline;
    }
    .icon {
      font-size: 14px;
    }
  }
`;

const steps = ["Order Confirmed", "Order processing", "Shipped", "Delivered"];

const DraftDetail = ({ setPageTitle }) => {
  const { draftId } = useParams();

  const [fetchTransactions, { isLoading, state: transactionData }] = useRequest();

  const [removeRequest] = useRequest();

  const { isDesktop } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    setPageTitle &&
      setTimeout(() => {
        setPageTitle("Draft Detail");
      }, 0);
  }, []);

  const removeProduct = async (seller) => {
    const response = await removeRequest({ path: `/order-draft/${draftId}/modify/${seller}`, method: "DELETE" });

    if (response.success) {
      if (response.data.qty == 0) {
        navigate("/account/order-drafts");
        return;
      }
      fetchTransactions({ path: `/order-draft/${draftId}/show-ungroup` });
    }
  };

  useEffect(() => {
    draftId && fetchTransactions({ path: `/order-draft/${draftId}/show-ungroup` });
  }, [draftId]);

  useEffect(() => {
    // if (!transactionData?.success) {
    //   navigate('/account/order-drafts')
    // }

    if (transactionData?.success && transactionData?.data?.items?.length == 0) {
      navigate("/account/order-drafts");
    }
  }, [transactionData]);

  const payment = () => {
    navigate(`/checkout-order-draft/${draftId}`);
  };

  const overAllShippingCharge = transactionData?.data?.shippingCharge;

  return (
    <>
      <TransactionDetailsStyle>
        <div id="transaction__data__wrapper">
          <div className="transaction__header">
            <div className="bold__title">Draft Detail:</div>

            {transactionData?.data?.draftId && (
              <div className="data customer__name">Draft ID : {transactionData?.data?.draftId}</div>
            )}
            {transactionData?.data?.billingAddress && (
              <div className="data">Billing Address : {transactionData?.data?.billingAddress}</div>
            )}
            {transactionData?.data?.shippingAddress && (
              <div className="data">Shipping Address : {transactionData?.data?.shippingAddress}</div>
            )}
            {transactionData?.data?.date && (
              <div className="sales__info">
                <span>Draft Date:</span> {getDate(transactionData?.data?.date)}
              </div>
            )}
          </div>
          <div className="transaction__order__summary">
            <div className="transaction__order__summary__header">
              <div className="transaction__order__th">Order Summary</div>
              <div className="transaction__order__th">Shipping</div>
              <div className="transaction__order__th">Price</div>
              <div className="transaction__order__th">Qty</div>
              <div className="transaction__order__th">Subtotal</div>
              <div className="transaction__order__th">Action</div>
            </div>
            <div className="transaction__order__summary__body">
              {transactionData?.data?.items?.map((item) => (
                <div className="transaction__order__summary__row">
                  <div className="transaction__order__td">
                    <div className="transaction__item__details">
                      <div className="transaction__item__image">
                        <img
                          src={staticUrl + "/" + (item?.image || item?.product?.image || "")}
                          alt={item?.name || item?.product?.name || ""}
                        />
                      </div>
                      <div className="transaction__item__info">
                        <div
                          className="product__name"
                          onClick={() => {
                            navigate(item?.productId ? `/product/${item?.productId?.slug}?id=${item?.productId?.id}` : `/not-found`);
                          }}
                        >
                          {item?.name || item?.product?.name || ""}
                        </div>
                        <div className="product__description">
                          {(item?.description && parse(item?.description)) ||
                            (item?.product?.description && parse(item?.product?.description)) ||
                            ""}
                        </div>
                        {getParsedVariations(item?.variation)?.parsed?.map((variation) => (
                          <div className="product__description attribute">
                            <span>{variation?.name}: </span>
                            {variation?.value}
                          </div>
                        ))}
                        <div className="product__description sku">
                          <span>SKU: </span>
                          {item?.sku || item?.product?.sku || ""}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="transaction__order__td">
                    {priceSymbol}
                    {Number(overAllShippingCharge || 0).toFixed(2)}
                  </div>
                  <div className="transaction__order__td">
                    {priceSymbol}
                    {(item?.price || item?.product?.price || 0).toFixed(2)}
                  </div>
                  <div className="transaction__order__td">{item?.quantity}</div>
                  <div className="transaction__order__td">
                    {priceSymbol}
                    {Number((item?.price || item?.product?.price || 0) * item?.quantity).toFixed(2)}
                  </div>

                  <div className="transaction__order__td">
                    <button className="checkout__now__button" onClick={() => removeProduct(item?.product?.seller)}>
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="transaction__order__summary__footer">
              Subtotal:{" "}
              <span>
                {priceSymbol}
                {transactionData?.data?.subtotal?.toFixed(2)}
              </span>
            </div>
            <div className="transaction__payment__info">
              <div className="transaction__payment__method"></div>
              <div>
                <div className="transaction__order__total">
                  <span>Discount:</span>
                  {priceSymbol}
                  {transactionData?.data?.couponDiscount?.toFixed(2)}
                </div>
                <div className="transaction__order__total">
                  <span>V&B Rewards:</span>
                  {priceSymbol}
                  {transactionData?.data?.usedReward?.toFixed(2)}
                </div>
                <div className="transaction__order__total">
                  <span>Shipping Charge:</span>
                  {priceSymbol}
                  {transactionData?.data?.shippingCharge?.toFixed(2)}
                </div>
                <div className="transaction__order__total">
                  <span>GST:</span>
                  {priceSymbol}
                  {transactionData?.data?.tax?.gst?.toFixed(2)}
                </div>
                <div className="transaction__order__total">
                  <span>PST/HST:</span>
                  {priceSymbol}
                  {transactionData?.data?.tax?.pst?.toFixed(2)}
                </div>
                <div className="transaction__order__total bold">
                  <span>Order Total:</span>
                  {priceSymbol}
                  {transactionData?.data?.total?.toFixed(2)}
                </div>

                <button
                  className="checkout__now__button"
                  onClick={payment}
                  disabled={transactionData?.data?.shippingStatus !== "calculated"}
                >
                  {" "}
                  Confirm Order
                </button>
              </div>
            </div>
          </div>
        </div>
        {transactionData?.data?.logs?.length > 0 && (
          <div className="transaction__logs">
            <div className="bold__title">Logs:</div>
            {transactionData?.data?.logs?.map((item) => (
              <div className="log__info">
                {item?.log} - <span>{moment.utc(item?.date).local().format("DD MM YYYY [at] hh:mm:ss A")}</span>
              </div>
            ))}
          </div>
        )}
      </TransactionDetailsStyle>
    </>
  );
};

export default DraftDetail;
