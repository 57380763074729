import React from "react";
import { getYouTubeId } from "../../utils/helper";
import YouTube from "react-youtube";

function YoutubeWrapper({ bannerUrl }) {
  if (!bannerUrl) return null;

  return (
    <YouTube
      videoId={getYouTubeId(bannerUrl)}
      opts={{
        // height: '400',
        width: "100%",
        playerVars: {
          autoplay: 0,
        },
      }}
    />
  );
}

export default YoutubeWrapper;
